<template>
  <div class="site-modal">
    <div class="site-modal__close" @click="closeModal()">
      <svg width="25px" height="27px" viewBox="0 0 25 27" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g  stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round">
          <g transform="translate(-1818.000000, -52.000000)" fill-rule="nonzero" stroke="#aa9f4c" stroke-width="2">
            <g id="Group-2" transform="translate(1819.000000, 53.000000)">
              <path d="M0.766666667,24.21875 L22.2333333,0.78125"></path>
              <path d="M0.766666667,24.21875 L22.2333333,0.78125" transform="translate(11.500000, 12.500000) scale(-1, 1) translate(-11.500000, -12.500000) "></path>
            </g>
          </g>
        </g >
      </svg>
    </div>
    <div class="container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    props: [
      'content',
    ],
    data() {
      return {
        htmlEl: {},
        uploaded: {},
      }
    },
    methods: {
      closeModal(e) {
        this.htmlEl.classList.remove('overlay-open');
        this.$emit('close', e);
      }
    },
    mounted() {
      const vm = this;
      const forms = document.getElementsByClassName('wpcf7-form');
      const uploaderInput = document.getElementById('JS-upload-input');
      const uploaderButton = document.getElementById('JS-upload-button');
      const positionInput = document.getElementById('JS-position-input');
      const positionString = document.getElementById('JS-hidden-value');

      this.htmlEl = document.getElementsByTagName('html')[0];
      this.htmlEl.classList.toggle('overlay-open');

      document.body.addEventListener('keyup', e => {
        if (e.keyCode === 27) {
          this.closeModal();
        }
      });

      if (forms.length) {
        wpcf7.initForm(forms);
        if (positionInput) {
          positionInput.value = positionString.innerText;
        }
        if (uploaderInput) {
          uploaderInput.addEventListener('change', (event) => {
            if (event.target.files) {
              uploaderButton.innerText = event.target.files[0].name
            }
          });
        }
        // Prevent Default CF7 submit.
        document.addEventListener( 'wpcf7mailsent', function( event ) {
          const message = event.detail.apiResponse.message || "Error";
          vm.closeModal(message);
        }, false);
      }
    }
  }
</script>