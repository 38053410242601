var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "site-modal__trigger",
      attrs: { href: "#" },
      on: {
        click: function ($event) {
          return _vm.openModal($event)
        },
      },
    },
    [_vm._t("trigger")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }