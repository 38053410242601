<template>
  <a href="#" class="site-modal__trigger" @click="openModal($event)">
    <slot name="trigger"/>
  </a>
</template>

<script>
  export default {
    methods: {
      openModal(e) {
        e.preventDefault();
        this.$emit('open', this.$slots.modal);
      }
    }
  }
</script>