import Vue from 'vue';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import { headroom } from 'vue-headroom';
import AOS from 'aos';
import imagesLoaded  from 'imagesloaded';

Vue.use(VueAwesomeSwiper);

import Modal from './components/modal/vs-modal';
import ModalTrigger from './components/modal/vs-modal-trigger';
import Dropdown from './components/dropdown/vs-dropdown';


import 'swiper/src/swiper.scss';
import './scss/main.scss';



new Vue({
  el: '#app',
  components: {
    headroom,
    Dropdown,
    Modal,
    ModalTrigger,
    ModalContent : {
      name: 'ModalContent',
      functional: true,
      render: (h, ctx) => ctx.props.content
    }
  },
  data: {
    html: {},
    body: {},
    loading: true,
    initialized: false,
    background: false,
    modal: {
      content: null,
    },
    drawer: {
      active: false,
    },
    messages: {
      alert: null
    },
    swiperOptions: {
      loop: true,
      simulateTouch: false,
      speed: 800,
      navigation: {
        nextEl: '.swiper-button--next',
        prevEl: '.swiper-button--prev'
      },
      autoplay: {
        delay: 9000,
      },
    },
      swiperOptions2: {
          loop: true,
          simulateTouch: true,
          slidesPerView: 'auto',
          speed: 400,
          navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
          },
          pagination: {
              el: '.swiper-pagination',
              clickable: true
          },
          autoplay: {
              delay: 3000,
          },
          breakpoints: {
              1024: {
                  slidesPerView: 3,
                  spaceBetween: 40
              },
              640: {
                  slidesPerView: 2,
                  spaceBetween: 20
              },
              320: {
                  slidesPerView: 1,
                  spaceBetween: 10
              }
          }
      }
  },
  created() {
    this.initialized = !!localStorage.getItem('initialized');
  },
  mounted() {
    AOS.init({
      duration: 1000,
    });

    this.html = document.getElementsByTagName('html')[0];
    this.body = document.getElementsByTagName('body')[0];
    this.body.classList.add('page--loaded');
    this.background = this.html.classList.contains('page--dark');

    imagesLoaded('.background-loader', {background: true}, () => {
      if (this.initialized) {
        this.loading = false;
      } else {
        setTimeout(() => {
          localStorage.setItem('initialized', 'true');
          this.initialized = true;
          this.loading = false;
        }, 2000)
      }
    });


    setTimeout(()=>{
      if (this.$refs[`slide-0`]) {
        const hex = this.$refs[`slide-0`].$attrs['data-background'];
        const cover = document.getElementsByClassName('swiper-slide__background-color')[0];
        cover.style.backgroundColor = hex;
      }
    },0);

  },
  computed: {
    swiper() {
      return this.$refs.swiperHome.swiper
    }
  },

  methods: {
    // Nav Drawer
    toggleDrawer() {
      this.drawer.active = !this.drawer.active;
      this.html = document.getElementsByTagName('html')[0];
      this.html.classList.toggle('overlay-open');
    },

    // Modal
    openModal(e) {
      this.modal.content = e;
    },
    closeModal(e) {
      this.modal.content = null;
      if (e) this.messages.alert = e;
    },

    // Alerts
    closeAlert() {
      this.messages.alert = null;
    },

    // Gallery
    slideChange(e) {
      let activeIndex = this.swiper.activeIndex;
      const slidesLength = this.swiper.slides.length;
      if (this.swiper.params.loop) {
        switch(this.swiper.activeIndex){
          case 0:
            activeIndex = slidesLength - 3;
            break;
          case slidesLength - 1:
            activeIndex = 0;
            break;
          default:
            --activeIndex;
        }
      }
      const hex = this.$refs[`slide-${activeIndex}`].$attrs['data-background'];
      const cover = document.getElementsByClassName('swiper-slide__background-color')[0];
      cover.style.backgroundColor = hex;
    },

    slideClick(sw, e) {
      console.log('clicked', sw, e);
      e.preventDefault();
      alert('clickSlide');
    }

  }
});






