var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vs-dropdown" },
    [
      _c(
        "button",
        {
          staticClass: "btn btn-outline-secondary",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.toggle()
            },
          },
        },
        [
          _c("div", [_vm._t("trigger")], 2),
          _vm._v(" "),
          _c(
            "svg",
            {
              attrs: {
                width: "18px",
                height: "9px",
                viewBox: "0 0 18 9",
                version: "1.1",
                xmlns: "http://www.w3.org/2000/svg",
                "xmlns:xlink": "http://www.w3.org/1999/xlink",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "Group",
                    transform:
                      "translate(9.000000, 5.000000) rotate(90.000000) translate(-9.000000, -5.000000) translate(5.000000, -4.000000)",
                    "fill-rule": "nonzero",
                  },
                },
                [
                  _c("path", { attrs: { d: "M0.5,1.5 L6.5,8.5" } }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M1.38976994,8.10250227 L7.30977531,16.0714286",
                      transform:
                        "translate(4.000000, 12.500000) scale(-1, 1) translate(-4.000000, -12.500000) ",
                    },
                  }),
                ]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.active ? _vm._t("content") : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }