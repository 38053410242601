<template>
  <div class="vs-dropdown">
    <button class="btn btn-outline-secondary" @click.stop="toggle()">
      <div>
        <slot name="trigger"></slot>
      </div>
      <svg width="18px" height="9px" viewBox="0 0 18 9" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Group" transform="translate(9.000000, 5.000000) rotate(90.000000) translate(-9.000000, -5.000000) translate(5.000000, -4.000000)" fill-rule="nonzero">
          <path d="M0.5,1.5 L6.5,8.5"></path>
          <path d="M1.38976994,8.10250227 L7.30977531,16.0714286" transform="translate(4.000000, 12.500000) scale(-1, 1) translate(-4.000000, -12.500000) "></path>
        </g>
      </svg>
    </button>
    <slot name="content" v-if="active"></slot>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        active: false,
      }
    },
    methods: {
      toggle() {
        this.active = !this.active;
      }
    },
    created() {
      const vm = this;
      document.addEventListener('click', () => {
        vm.active = false;
      })
    },
  }
</script>