var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "site-modal" }, [
    _c(
      "div",
      {
        staticClass: "site-modal__close",
        on: {
          click: function ($event) {
            return _vm.closeModal()
          },
        },
      },
      [
        _c(
          "svg",
          {
            attrs: {
              width: "25px",
              height: "27px",
              viewBox: "0 0 25 27",
              version: "1.1",
              xmlns: "http://www.w3.org/2000/svg",
              "xmlns:xlink": "http://www.w3.org/1999/xlink",
            },
          },
          [
            _c(
              "g",
              {
                attrs: {
                  stroke: "none",
                  "stroke-width": "1",
                  fill: "none",
                  "fill-rule": "evenodd",
                  "stroke-linecap": "round",
                },
              },
              [
                _c(
                  "g",
                  {
                    attrs: {
                      transform: "translate(-1818.000000, -52.000000)",
                      "fill-rule": "nonzero",
                      stroke: "#aa9f4c",
                      "stroke-width": "2",
                    },
                  },
                  [
                    _c(
                      "g",
                      {
                        attrs: {
                          id: "Group-2",
                          transform: "translate(1819.000000, 53.000000)",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M0.766666667,24.21875 L22.2333333,0.78125",
                          },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d: "M0.766666667,24.21875 L22.2333333,0.78125",
                            transform:
                              "translate(11.500000, 12.500000) scale(-1, 1) translate(-11.500000, -12.500000) ",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "container" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }